@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'assets/css/font-awesome.min.css';
@import '~react-vertical-timeline-component/style.min.css';

body {
  font-family : 'Hind', sans-serif;
  font-size   : 15px;
  line-height : 1.8em;
  color       : #444444;
  font-weight : 300;
  background  : #f8f8f8;
  border-top: 5px solid #1769aa
}

h1, h2, h3, h4, h5, h6 {
  margin : 0 0 15px;
}

h1 {
  font-size   : 36px;
  line-height : 1.8em;
  font-weight : 700;
}

h2 {
  font-size   : 18px;
  line-height : 1.8em;
  font-weight : 700;
}

h3 {
  font-size   : 16px;
  line-height : 1.8em;
  font-weight : 700;
}

h4 {
  font-size   : 15px;
  line-height : 1.8em;
  font-weight : 500;
}

h5 {
  font-size   : 15px;
  line-height : 1.5em;
  font-weight : 500;
}

h6 {
  font-size   : 15px;
  line-height : 1.5em;
}

.information {
  display: none;
}

.information > h3, small {
  color: #1769aa !important;
}

a,
a:active,
a:focus,
a:hover,
a:focus,
a:active {
  text-decoration : none;
  outline         : none
}